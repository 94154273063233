import React from 'react'
import styled from 'styled-components'
import Videos from '../../components/Videos'

const Container = styled.section`
  width: 100%;
  max-width: ${props => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
`

const CultureVideos = ({ pageContext: { videos } }) => (
  <Container className="section">
    <Videos className="section" videos={videos} />
  </Container >
)

export default CultureVideos
